<template>
  <div :class="['Table', { 'Table--mobile': mobile.isMobile }]">
    <!-- If you want a heading and subheading in the top of the table -->
    <!-- Content passed here should have class: Table__slot-content -->
    <slot />

    <div
      v-if="!mobile.isMobile"
      class="Table__head-wrapper row row--justify-between"
    >
      <label class="Table__header-lbl Table__header-lbl--BaseBalanceTable">DATE</label>
      <label class="Table__header-lbl Table__header-lbl--BaseBalanceTable">TYPE</label>
      <label class="Table__header-lbl Table__header-lbl--BaseBalanceTable">+/-</label>
      <label class="Table__header-lbl Table__header-lbl--BaseBalanceTable">BALANCE</label>
    </div>
    <transition-group name="Transition__opacity-fade">
      <div
        v-for="(balance, index) in balancesWInvoices"
        :key="`balances-${index}`"
        class="Table__item--BaseBalanceTable Table__item-wrapper Table__item-wrapper--BaseBalanceTable row"
        :data-cy="`base-balance-table-row-${index}`"
      >
        <!-- DATE -->
        <div class="column">
          <time
            class="fc-light fs-14 uppercase"
            :datetime="balance.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ balance.created_at | datetime('MM/DD/YYYY') }}
          </time>
        </div>
        <!-- TYPE -->
        <div class="column">
          <template v-if="balance.type === 'debtor payment'">
            <label class="fs-15 capitalize">
              <!-- MOBILE ONLY -->
              <time
                class="fs-15"
                :datetime="balance.created_at | datetime('YY-MM-DD hh:mm:ssZ')"
              >
                {{ balance.created_at | datetime('MM/DD/YY') }} -
              </time>
              {{ debtorPaymentText(balance.reason) }}
            </label>
            <label
              @click="viewUniversalSearch(balance.load_number)"
              :class="['fc-light fs-13', { 'clickable': !mobile.isMobile }]"
            >
              {{ balance.debtor.toUpperCase() }}, Load #{{ balance.load_number }}
            </label>
          </template>
          <template v-else-if="balance.type === 'approved invoice'">
            <label class="fs-15 capitalize">
              <!-- MOBILE ONLY -->
              <time
                class="fs-15"
                :datetime="balance.created_at | datetime('YY-MM-DD hh:mm:ssZ')"
              >
                {{ balance.created_at | datetime('MM/DD/YY') }} -
              </time>
              Chargeback
            </label>
            <label
              @click="viewUniversalSearch(balance.load_number)"
              :class="['fc-light fs-13', { 'clickable': !mobile.isMobile }]"
            >
              {{ balance.debtor }}, Load #{{ balance.load_number }}
            </label>
          </template>
          <template v-else-if="balance.type === 'client adjustments'">
            <label
              class="fs-15 capitalize"
            >
              <!-- MOBILE ONLY -->
              <time
                class="fs-15"
                :datetime="balance.created_at | datetime('YY-MM-DD hh:mm:ssZ')"
              >
                {{ balance.created_at | datetime('MM/DD/YY') }} -
              </time>
              <label
                class="fs-15"
                data-cy="base-balance-table-adjustment-reason"
              >
                Adjustment - {{ balance.reason }}
                (by {{ balance.updated_by_user.employee
                  ? balance.updated_by_user.employee.first_name
                  : 'System'
                }})
              </label>
            </label>
            <label
              v-if="balance.reason === 'release to 3rd party'"
              class="fc-light fs-13 capitalize"
              data-cy="base-balance-table-note"
            >
              {{ balance.metadata.third_party }}
            </label>
            <label
              v-else
              class="fc-light fs-13"
              data-cy="base-balance-table-note"
            >
              {{ balance.notes }}
            </label>
          </template>
          <template v-else>
            <label class="fs-15 capitalize">
              <!-- MOBILE ONLY -->
              <time
                class="fs-15"
                :datetime="balance.created_at | datetime('YY-MM-DD hh:mm:ssZ')"
              >
                {{ balance.created_at | datetime('MM/DD/YY') }} -
              </time>
              {{ balance.type }}
            </label>
            <label class="fc-light fs-13">{{ balance.notes }}</label>
          </template>
        </div>
        <!-- +/- -->
        <div class="column">
          <label
            :class="['fs-14', {
              'fc-dark': balance.nacha_sign === '+',
              'fc-red': balance.nacha_sign === '-'
            }]"
            :data-cy="`base-balance-table-change-amount-${index}`"
          >
            <span
              v-if="balance.nacha_sign === '-'"
              class="Table__nacha_sign fc-red"
            >
              -
            </span>
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format((balance.amount / 100))
            }}
          </label>
        </div>
        <!-- BALANCE -->
        <div class="column">
          <label
            class="row row--align-center row--justify-between fc-light fs-14"
            :data-cy="`base-balance-table-total-${index}`"
          >
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format((balance.total / 100))
            }}

            <button
              v-if="
                deletableAdjustmentOptions.includes(balance.reason)
                  && !balance.deleted_at
                  && balance.client_paymentid === null
              "
              @click="openRemoveAdjustment(balance)"
              :data-cy="`Testing__BaseBalanceDelete-${index}`"
            >
              <i class="fa fa-trash-can fa--left fa-15" />
            </button>
          </label>
        </div>
      </div>
    </transition-group>

    <!-- DELETE MODAL -->
    <base-item-delete
      v-if="deleting"
      @cancel="deleting = false"
      @delete="deleteAdjustment"
      :confirm-text="'Yes, Delete Adjustment'"
      :delete-text="
        `Are you sure you want to delete this adjustment for ${
          Intl
            .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format((deletingAdjustment.amount / 100))
        }?`
      "
    />
  </div>
</template>

<script>
// Helpers
import {
  Balance,
  Invoice
} from '../utils/api'
import { sanitizeAlgoliaNames } from '../utils/helpers'
// Components
import BaseItemDelete from './base-item-delete.vue'

export default {
  name: 'BaseBalanceTable',

  components: {
    BaseItemDelete
  },

  props: {
    balances: {
      type: Array,
      required: true,
    },
  },

  async created () {
    await this.getBalancesWInvoices()
  },

  data () {
    return {
      balancesWInvoices: [],
      deletableAdjustmentOptions: [
        'overadvance',
        'client credit',
        'non-factored payment',
        'release of funds',
        'release to 3rd party',
        'direct payment by client',
        'fee',
        'debtor claim',
        'write off'
      ],
      deleting: false,
      deletingAdjustment: null
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },

  watch: {
    balances () {
      this.getBalancesWInvoices()
    }
  },

  methods: {
    debtorPaymentText (reason) {
      if (reason === 'shortpay') return 'Shortpaid'
      if (reason === 'overpay') return 'Overpaid'
      if (reason === 'nonpayment') return 'Non-payment'
      return reason
    },

    async deleteAdjustment () {
      if (this.deletingAdjustment.client_paymentid !== null) {
        return
      }
      this.progressStart()
      this.deleting = false

      try {
        const newBalance = await Balance.delete(this.deletingAdjustment.id)
        this.$emit('load-balances', null, newBalance)
        this.progressFinish()
      } catch (error) {
        this.captureSentryEvent(
          'Delete Balance Adjustment Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue deleting the adjustment'
        })
      }
    },

    async getBalanceInvoiceInfo (balance) {
      // Don't get invoice info if balance.type is NOT 'debtor payment' or 'approved invoice'
      if (!['debtor payment', 'approved invoice'].includes(balance.type) || !balance.invoice_id) return balance
      const newBalance = balance

      try {
        const invoice = (await Invoice.get(newBalance.invoice_id)).data
        newBalance.debtor = this.sanitizeAlgoliaNames(invoice.debtor_selected_name)
        newBalance.load_number = invoice.load_number
        return newBalance
      }
      catch (error) {
        this.captureSentryEvent(
          "Get a Balance's Invoice Info Error",
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue getting the balance\'s invoice information'
        })
      }
    },

    async getBalancesWInvoices () {
      this.balancesWInvoices = []
      this.progressStart()

      // get invoice info and keep balances in the same order
      // the following variable is required to update this.balancesWInvoices all at once instead of
      // incrementally, which if incrementally, creates a vertical transition like effect
      const balancesWInvoices = []
      // eslint-disable-next-line no-restricted-syntax
      for (const balance of this.balances) {
        try {
          // eslint-disable-next-line no-await-in-loop
          balancesWInvoices.push(await this.getBalanceInvoiceInfo(balance))
        }
        catch (error) {
          this.captureSentryEvent(
            'Get Balances With Invoices Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
        }
      }
      this.balancesWInvoices = balancesWInvoices

      this.progressFinish()
    },

    sanitizeAlgoliaNames,

    viewUniversalSearch (loadNumber) {
      this.$router.push({
        name: 'search-results',
        // Since we must use query in invoice-updates when routing to search-results,
        // we must also use query here for simplicity and maintenance, as well as ensuring
        // the route query string in search-results is always accurate to what is currently
        // being searched
        query: {
          loadNumber,
        }
      })
    },

    async openRemoveAdjustment (adjustment) {
      this.deletingAdjustment = adjustment
      this.deleting = true
    }
  },
}
</script>

<style lang="sass">
@import '../styles/tables'

.Table

  &--mobile
    border-bottom: $border
    border-top: $border
</style>
