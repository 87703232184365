var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["Table", { "Table--mobile": _vm.mobile.isMobile }] },
    [
      _vm._t("default"),
      !_vm.mobile.isMobile
        ? _c(
            "div",
            { staticClass: "Table__head-wrapper row row--justify-between" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "Table__header-lbl Table__header-lbl--BaseBalanceTable"
                },
                [_vm._v("DATE")]
              ),
              _c(
                "label",
                {
                  staticClass:
                    "Table__header-lbl Table__header-lbl--BaseBalanceTable"
                },
                [_vm._v("TYPE")]
              ),
              _c(
                "label",
                {
                  staticClass:
                    "Table__header-lbl Table__header-lbl--BaseBalanceTable"
                },
                [_vm._v("+/-")]
              ),
              _c(
                "label",
                {
                  staticClass:
                    "Table__header-lbl Table__header-lbl--BaseBalanceTable"
                },
                [_vm._v("BALANCE")]
              )
            ]
          )
        : _vm._e(),
      _c(
        "transition-group",
        { attrs: { name: "Transition__opacity-fade" } },
        _vm._l(_vm.balancesWInvoices, function(balance, index) {
          return _c(
            "div",
            {
              key: "balances-" + index,
              staticClass:
                "Table__item--BaseBalanceTable Table__item-wrapper Table__item-wrapper--BaseBalanceTable row",
              attrs: { "data-cy": "base-balance-table-row-" + index }
            },
            [
              _c("div", { staticClass: "column" }, [
                _c(
                  "time",
                  {
                    staticClass: "fc-light fs-14 uppercase",
                    attrs: {
                      datetime: _vm._f("datetime")(
                        balance.created_at,
                        "YYYY-MM-DD hh:mm:ssZ"
                      )
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("datetime")(balance.created_at, "MM/DD/YYYY")
                        ) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "column" },
                [
                  balance.type === "debtor payment"
                    ? [
                        _c("label", { staticClass: "fs-15 capitalize" }, [
                          _c(
                            "time",
                            {
                              staticClass: "fs-15",
                              attrs: {
                                datetime: _vm._f("datetime")(
                                  balance.created_at,
                                  "YY-MM-DD hh:mm:ssZ"
                                )
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("datetime")(
                                      balance.created_at,
                                      "MM/DD/YY"
                                    )
                                  ) +
                                  " -\n            "
                              )
                            ]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.debtorPaymentText(balance.reason)) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "label",
                          {
                            class: [
                              "fc-light fs-13",
                              { clickable: !_vm.mobile.isMobile }
                            ],
                            on: {
                              click: function($event) {
                                return _vm.viewUniversalSearch(
                                  balance.load_number
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(balance.debtor.toUpperCase()) +
                                ", Load #" +
                                _vm._s(balance.load_number) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    : balance.type === "approved invoice"
                    ? [
                        _c("label", { staticClass: "fs-15 capitalize" }, [
                          _c(
                            "time",
                            {
                              staticClass: "fs-15",
                              attrs: {
                                datetime: _vm._f("datetime")(
                                  balance.created_at,
                                  "YY-MM-DD hh:mm:ssZ"
                                )
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("datetime")(
                                      balance.created_at,
                                      "MM/DD/YY"
                                    )
                                  ) +
                                  " -\n            "
                              )
                            ]
                          ),
                          _vm._v("\n            Chargeback\n          ")
                        ]),
                        _c(
                          "label",
                          {
                            class: [
                              "fc-light fs-13",
                              { clickable: !_vm.mobile.isMobile }
                            ],
                            on: {
                              click: function($event) {
                                return _vm.viewUniversalSearch(
                                  balance.load_number
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(balance.debtor) +
                                ", Load #" +
                                _vm._s(balance.load_number) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    : balance.type === "client adjustments"
                    ? [
                        _c("label", { staticClass: "fs-15 capitalize" }, [
                          _c(
                            "time",
                            {
                              staticClass: "fs-15",
                              attrs: {
                                datetime: _vm._f("datetime")(
                                  balance.created_at,
                                  "YY-MM-DD hh:mm:ssZ"
                                )
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("datetime")(
                                      balance.created_at,
                                      "MM/DD/YY"
                                    )
                                  ) +
                                  " -\n            "
                              )
                            ]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "fs-15",
                              attrs: {
                                "data-cy":
                                  "base-balance-table-adjustment-reason"
                              }
                            },
                            [
                              _vm._v(
                                "\n              Adjustment - " +
                                  _vm._s(balance.reason) +
                                  "\n              (by " +
                                  _vm._s(
                                    balance.updated_by_user.employee
                                      ? balance.updated_by_user.employee
                                          .first_name
                                      : "System"
                                  ) +
                                  ")\n            "
                              )
                            ]
                          )
                        ]),
                        balance.reason === "release to 3rd party"
                          ? _c(
                              "label",
                              {
                                staticClass: "fc-light fs-13 capitalize",
                                attrs: { "data-cy": "base-balance-table-note" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(balance.metadata.third_party) +
                                    "\n          "
                                )
                              ]
                            )
                          : _c(
                              "label",
                              {
                                staticClass: "fc-light fs-13",
                                attrs: { "data-cy": "base-balance-table-note" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(balance.notes) +
                                    "\n          "
                                )
                              ]
                            )
                      ]
                    : [
                        _c("label", { staticClass: "fs-15 capitalize" }, [
                          _c(
                            "time",
                            {
                              staticClass: "fs-15",
                              attrs: {
                                datetime: _vm._f("datetime")(
                                  balance.created_at,
                                  "YY-MM-DD hh:mm:ssZ"
                                )
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("datetime")(
                                      balance.created_at,
                                      "MM/DD/YY"
                                    )
                                  ) +
                                  " -\n            "
                              )
                            ]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(balance.type) +
                              "\n          "
                          )
                        ]),
                        _c("label", { staticClass: "fc-light fs-13" }, [
                          _vm._v(_vm._s(balance.notes))
                        ])
                      ]
                ],
                2
              ),
              _c("div", { staticClass: "column" }, [
                _c(
                  "label",
                  {
                    class: [
                      "fs-14",
                      {
                        "fc-dark": balance.nacha_sign === "+",
                        "fc-red": balance.nacha_sign === "-"
                      }
                    ],
                    attrs: {
                      "data-cy": "base-balance-table-change-amount-" + index
                    }
                  },
                  [
                    balance.nacha_sign === "-"
                      ? _c(
                          "span",
                          { staticClass: "Table__nacha_sign fc-red" },
                          [_vm._v("\n            -\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(balance.amount / 100)
                        ) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "column" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "row row--align-center row--justify-between fc-light fs-14",
                    attrs: { "data-cy": "base-balance-table-total-" + index }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(balance.total / 100)
                        ) +
                        "\n\n          "
                    ),
                    _vm.deletableAdjustmentOptions.includes(balance.reason) &&
                    !balance.deleted_at &&
                    balance.client_paymentid === null
                      ? _c(
                          "button",
                          {
                            attrs: {
                              "data-cy": "Testing__BaseBalanceDelete-" + index
                            },
                            on: {
                              click: function($event) {
                                return _vm.openRemoveAdjustment(balance)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-trash-can fa--left fa-15"
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            ]
          )
        }),
        0
      ),
      _vm.deleting
        ? _c("base-item-delete", {
            attrs: {
              "confirm-text": "Yes, Delete Adjustment",
              "delete-text":
                "Are you sure you want to delete this adjustment for " +
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD"
                }).format(_vm.deletingAdjustment.amount / 100) +
                "?"
            },
            on: {
              cancel: function($event) {
                _vm.deleting = false
              },
              delete: _vm.deleteAdjustment
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }